import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faCloudArrowDown,
    faEllipsis,
    faPlusCircle,
    faShareNodes, faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {
    downloadFile,
    fileUpload,
    getDirArray,
    getNavigateBackData,
    getNavigateToDirData,
    saveData
} from "../Base/functions";
import {completeDeleteFile, navigateBack, navigateToDir, setActiveFile, storeFileList} from "../reducers/FileReducer";
import {setModalActive, setUploadProgress} from "../reducers/ModalReducer";
import config from "../Base/config";
import {i18n} from "../reducers/TranslationReducer";

const FilesHeader = (props) => {
    const handleShareFiles = props.handleShareFiles;

    const back = () => {
        props.navigateBack();
    }

    const handleNewFolder = () => {
        props.setModalActive({
            active: true,
            type  : 'newFolder',
        });
    }

    const handleFileDownload = () => {
        if (props.file.selectedFiles.length > 0) {
            for (let i in props.file.selectedFiles) {
                let file = props.file.selectedFiles[i];
                downloadFile({ File_ID: file.File_ID })
            }
        }
    }

    const handleFileDelete = () => {
        props.setModalActive({
            active: true,
            type  : 'confirmDelete',
            data  : null
        })
    }

    return (
        <div className="file__header">
            <div className={'file__header__pathInfo'}>
                <div className={'file__header__pathInfo__backIcon'} onClick={back}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </div>
                {/*{props.path_arr.length > 0 && props.path_arr.map((item, index) => (*/}
                {/*    <div className={'file__header__pathInfo__elem'} key={index}*/}
                {/*         onClick={() => handlePath(index)}><span*/}
                {/*        className={'file__header__pathInfo__elem__slash'}>{index === 0 ? '@' : '/'}</span><span*/}
                {/*        className={'file__header__pathInfo__elem__text'}>{item.name}</span></div>*/}
                {/*))}*/}
            </div>
            <div className="file__header__options">
                {!props.shared &&
                    <>
                        <div
                            className={props.user.User_ID ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}
                            onClick={handleNewFolder}>
                            {props.user.User_ID && props.file.tree && Object.keys(props.file.tree).length === 0 &&
                                <div className="file__header__options__icon__tooltip__newFolder">
                                    {i18n.createFolderToStart}
                                </div>
                            }
                            <FontAwesomeIcon icon={faPlusCircle}/>
                            <div className="file__header__options__icon__text">
                                {i18n.newFolder}
                            </div>
                        </div>
                        <div
                            className={props.user.User_ID && (props.file.selectedFiles.length > 0) ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}
                            onClick={handleShareFiles}>
                            <FontAwesomeIcon icon={faShareNodes}/>
                            <div className="file__header__options__icon__text">
                                {i18n.share}
                            </div>
                        </div>
                        <div
                            className={props.user.User_ID && (props.file.selectedFiles.length > 0) ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}
                            onClick={handleFileDelete}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                            <div className="file__header__options__icon__text">
                                {i18n.delete}
                            </div>
                        </div>
                    </>
                }
                <div
                    className={(props.user.User_ID || props.shared) && props.file.selectedFiles.length > 0 ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}
                    onClick={handleFileDownload}>
                    <FontAwesomeIcon icon={faCloudArrowDown}/>
                    <div className="file__header__options__icon__text">
                        {i18n.download}
                    </div>

                </div>
                <div
                    className={props.user.User_ID ? "file__header__options__icon" : "file__header__options__icon file__header__options__icon--disabled"}>
                    <FontAwesomeIcon icon={faEllipsis}/>
                    <div className="file__header__options__icon__text">
                        {i18n.more}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user      : state.user,
    file      : state.file,
    sharedData: state.file.sharedData,
    path_arr  : state.file.active.path_arr,
})

const mapDispatchToProps = {
    setActiveFile,
    setModalActive,
    navigateBack,
    navigateToDir,
    setUploadProgress,
    storeFileList,
    completeDeleteFile,
    saveData,

}

export default connect(mapStateToProps, mapDispatchToProps)(FilesHeader)