import {connect} from "react-redux";
import FileDirPreview from "./FileDirPreview";
import FileFilePreview from "./FileFilePreview";
import {useState} from "react";
import {fileUpload, getDirArray, getNavigateToDirData, saveData} from "../Base/functions";
import {completeAddNewFile, setActiveFile, setXHR, storeFileList} from "../reducers/FileReducer";
import {NavLink} from "react-router-dom";
import {setModalActive, setUploadProgress} from "../reducers/ModalReducer";
import config from "../Base/config";
import {i18n} from "../reducers/TranslationReducer";

const FilePreview = (props) => {
    const [dragOver, setDragOver] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        if(!props.file.active.file && props.user.User_ID) {
            setDragOver(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        if (!['file__container__filePreview__dragInfo__content', 'file__container__filePreview__dragInfo'].includes(e.relatedTarget.className)) {
            setDragOver(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        if (e.dataTransfer.files && e.dataTransfer.files && !props.file.active.file && props.user.User_ID) {
            onFileDrop(e.dataTransfer.files);
        }
    };

    const onFileDrop = (files) => {
        if(!props.modal.active) {
            const fd = new FormData();
            for(let i in files) {
                fd.append(`file${i}`, files[i]);
            }
            fd.append('dir', props.file.active.dir || null);

            if (config.isLocalhost) {
                props.saveData({
                    route   : 'fileUpload',
                    formData: fd,
                    callBack: completeFileUpload,
                    response: () => null,
                })
            } else {
                fileUpload({
                    route   : 'fileUpload',
                    formData: fd,
                    progress: handleProgress,
                    callBack: completeFileUpload,
                    response: () => null,
                    xhr     : setXHR,
                })
            }
        }
    }

    const setXHR = (xhr) => {
        props.setXHR(xhr);
    }

    const handleProgress = (progress) => {
        if (!props.modal.active) {
            props.setModalActive({
                active: true,
                type  : 'progress',
            })
        }
        props.setUploadProgress(progress);
    }

    const completeFileUpload = async (data) => {
        if(data.newList && Object.keys(data.newList).length > 0) {
            await props.storeFileList(data.newList);
        }
        if(data.newFile) {
            props.completeAddNewFile(data.newFile);
        }
        props.setModalActive({
            active: false,
        })
    }

    return (
        <div
            className={dragOver ? "file__container__filePreview file__container__filePreview--dragover" : "file__container__filePreview"}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>
            {dragOver &&
                <div className="file__container__filePreview__dragInfo"
                     onDragOver={handleDragOver}>
                    <div className="file__container__filePreview__dragInfo__content">
                        {i18n.dropFileHere}
                    </div>
                </div>
            }
            {props.file.active.data && Object.keys(props.file.active.data).length > 0 && !props.file.active.file &&
                <FileDirPreview data={props.file.active.data} shared={props.shared} />
            }
            {props.file.active.file &&
                <FileFilePreview file={props.file.active.file} shared={props.shared}/>
            }
            {props.file.tree && Object.keys(props.file.tree).length === 0 &&
                <div className="file__container__filePreview__welcome">
                    <div className="file__container__filePreview__welcome__content">
                        <h1>👋 Welcome to your private cloud</h1>
                        <p>On your left you can navigate through your Files.</p>
                        <p>The right side is for Previews.</p>
                        <p>Above the preview window there are a few Icons for creating folders, sharing or download
                            files and more.</p>
                        <p>Have fun exploring your cloud.</p>
                        {!props.user.User_ID &&
                            <div className="file__container__filePreview__welcome__content__buttonContainer">
                                <NavLink to={'/User'}>
                                    <div
                                        className="file__container__filePreview__welcome__content__buttonContainer__button">
                                        {i18n.getStarted}
                                    </div>
                                </NavLink>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    file : state.file,
    user : state.user,
    modal: state.modal,
})

const mapDispatchToProps = {
    saveData,
    storeFileList,
    setActiveFile,
    setUploadProgress,
    setModalActive,
    setXHR,
    completeAddNewFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilePreview)