import {connect} from "react-redux";
import './user.scss';
import Login from "./Login";
import {useEffect, useState} from "react";
import Register from "./Register";
import {loadData} from "../Base/functions";

const User = (props) => {
    const [type, setType] = useState(0);

    const toggleType = (type) => {
        setType(type);
    }

    useEffect(() => {
        const main = document.getElementById('main');
        if(main) {
            main.style.backgroundColor = 'rgba(38, 38, 38, 0.68)';
        }
    },[])

    return (
        <div className="user">
            <div className="user__container">
                {/*<div className="user__container__types">*/}
                {/*    <div*/}
                {/*        className={type === 0 ? "user__container__types__type user__container__types__type--active" : "user__container__types__type"}*/}
                {/*        onClick={() => setType(0)}>Login*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*        className={type === 1 ? "user__container__types__type user__container__types__type--active" : "user__container__types__type"}*/}
                {/*        onClick={() => setType(1)}>Register*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="user__container__content">*/}
                {/*    {type === 0 ?*/}
                {/*        <Login/>*/}
                {/*        :*/}
                {/*        type === 1 ?*/}
                {/*            <Register toggleType={toggleType} />*/}
                {/*            : null*/}
                {/*    }*/}
                {/*</div>*/}
                <div className="user__container__content">
                    <Login/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    loadData,
}

export default connect(mapStateToProps, mapDispatchToProps)(User)