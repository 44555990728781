let localhost = false;
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    localhost = true;
}

let test = false;
if (localhost || window.location.hostname === 'test.alexhinterleitner.com') {
    test = true;
}

const activeSite = 'com';
// const activeSite = 'cloud';

const config = {
    baseURL      : localhost ? 'http://localhost/cloud/' : test ? 'https://test.alexhinterleitner.com/' : 'https://' + window.location.host + '/',
    serverBaseURL: localhost ? 'http://localhost:3000' : test ? 'https://test.alexhinterleitner.com' :  'https://' + window.location.host,
    audioURL     : 'homepage/src/Base/Media/Audio/',
    imageURL     : localhost ? 'http://localhost/cloud/src/Base/Media/Images/' :  'https://' + window.location.host + '/src/Base/Media/Images/',
    isLocalhost  : localhost,
    isTest       : test,
    isMobile     : {
        Android   : function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS       : function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera     : function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows   : function () {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any       : function () {
            return (config.isMobile.Android() || config.isMobile.BlackBerry() || config.isMobile.iOS() || config.isMobile.Opera() || config.isMobile.Windows());
        }
    },
    specialCharacters: ['/',';',':','!','?','^','%','$','=','"',"'",'@','´','`','*','+',','],
    activeSite,
    // wss          : test ? 'wss://wss.alexhinterleitner.com:3002' : 'wss://wss.alexhinterleitner.com:3001',
}

export default config;