import {connect} from "react-redux";
import Input from "../Components/Input";
import Form from "../Components/Form";
import {useState} from "react";
import {saveData} from "../Base/functions";

import {storeUser} from "../reducers/UserReducer";
import config from "../Base/config";

import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignIn, faTimes} from "@fortawesome/free-solid-svg-icons";

const Login = (props) => {
    const [userData, setUserData] = useState({
        mail_name    : '',
        password: '',
    })

    const navigate = useNavigate();

    const onSubmit = () => {
        let fd = new FormData();
        fd.append('data', JSON.stringify(userData));

        props.saveData({
            route   : 'login',
            formData: fd,
            callBack: loginUser,
            response: () => window.location.href = config.serverBaseURL,
        })
    }

    const loginUser = async (data) => {
        await props.storeUser(data);
        navigate('/');
    }

    const onChange = (key, value) => {
        let newData = {...userData};
        newData[key] = value;
        setUserData(newData);
    }

    return (
        <>
            <div className="input__header">
                <h3>Login</h3>
                <div className="input__header__close_icon">
                    <div>Close</div>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>
            <Form onSubmit={onSubmit} submitValue={'Login'}>
                <Input
                    value={userData.mail_name}
                    placeholder={'Enter your email / Username'}
                    name={'mail_name'}
                    onChange={onChange}
                    required
                    type={'mail'}
                    autoComplete={'off'}
                />
                <Input
                    value={userData.password}
                    placeholder={'Enter your password'}
                    name={'password'}
                    onChange={onChange}
                    type={'password'}
                    required
                    autoComplete={'off'}
                />
                <div className="input__remember">
                    <Input
                        checked={false}
                        label={'Remember me'}
                        type={'checkbox'}
                        flex_reverse
                    />
                    <div className={'input__forgot'}>
                        <a href="#">Forgot password?</a>
                    </div>
                </div>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
    saveData,
    storeUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)