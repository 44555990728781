import {connect} from "react-redux";
import {fileIcon, getNavigateBackData, saveData} from "../Base/functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {handleFileSelect, handleSkipFiles, setActiveFile} from "../reducers/FileReducer";
import {useEffect, useState} from "react";

const FileFilePreview = (props) => {
    const handleContent = () => {
        const type = props.file.active.data.type;
        const extension = props.file.active.data.extension;
        const path = props.file.active.data.file_dir + '/' + props.file.active.data.file_dir_name + '.' + props.file.active.data.extension;
        const username = props.shared ? props.file.active.data.user_hash : props.user.hash_name;
        return fileIcon(type, extension, path, username);
    }

    const back = () => {
        props.handleFileSelect([]);
        // props.setActiveFile(getNavigateBackData(props.file, props.shared))
        props.setActiveFile(props.file.active.prev_dir);
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    },[])

    const handleKeyDown = (e) => {
        if(e.key === 'Escape') {
            back();
        }
        if(e.key === 'ArrowRight') {
            skip('next');
        }
        if(e.key === 'ArrowLeft') {
            skip('prev');
        }
    }

    const skip = (dir) => {
        switch(dir) {
            case 'next': props.handleSkipFiles({type: 'next' }); break;
            case 'prev': props.handleSkipFiles({type: 'prev' }); break;
            default:
        }
    }

    if (!props.file || (props.file && props.file.data && Object.keys(props.file.data).length === 0)) return null;

    return (
        <div className="file__container__filePreview__files">
            <div className="file__container__filePreview__files__backIcon" onClick={back}>
                <FontAwesomeIcon icon={faArrowLeft}/>
            </div>
            <div className="file__container__filePreview__files__content__skipIcon" onClick={() => skip('prev')}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className="file__container__filePreview__files__content">
                {handleContent()}
            </div>
            <div className="file__container__filePreview__files__content__skipIcon" onClick={() => skip('next')}>
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user      : state.user,
    file      : state.file,
    sharedData: state.file.sharedData,
})

const mapDispatchToProps = {
    setActiveFile,
    handleFileSelect,
    saveData,
    handleSkipFiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileFilePreview)