const translations = {

    // **Authentifizierung und Nutzerkonto:**
    login           : {
        DE: 'Login',
        EN: 'Login',
    },
    register        : {
        DE: 'Registrieren',
        EN: 'Register',
    },
    username        : {
        DE: 'Benutzername',
        EN: 'Username',
    },
    password        : {
        DE: 'Passwort',
        EN: 'Password',
    },
    rememberPassword: {
        DE: 'Passwort merken',
        EN: 'Remember Password',
    },
    forgotPassword  : {
        DE: 'Passwort vergessen?',
        EN: 'Forgot Password?',
    },
    logout          : {
        DE: 'Abmelden',
        EN: 'Logout',
    },
    accessDenied    : {
        DE: 'Zugriff verweigert',
        EN: 'Access Denied',
    },


// **Dateioperationen:**
    upload          : {
        DE: 'Upload',
        EN: 'Upload',
    },
    progress        : {
        DE: 'Fortschritt',
        EN: 'Progress',
    },
    maxFileSize     : {
        DE: 'max. Dateigröße',
        EN: 'max. File size',
    },
    fileSizeExceeded: {
        DE: 'max. Dateigröße von XX überschritten!',
        EN: 'File size exceeds maximum of XX!',
    },
    timeout         : {
        DE: 'Zeitüberschreitung',
        EN: 'Timeout',
    },
    retry           : {
        DE: 'Erneut versuchen',
        EN: 'Retry',
    },
    download        : {
        DE: 'Download',
        EN: 'Download',
    },
    delete          : {
        DE: 'Löschen',
        EN: 'Delete',
    },
    cancel          : {
        DE: 'Abbrechen',
        EN: 'Cancel',
    },
    rename          : {
        DE: 'Umbenennen',
        EN: 'Rename',
    },
    move            : {
        DE: 'Verschieben',
        EN: 'Move',
    },
    newFolder       : {
        DE: 'Neuer Ordner',
        EN: 'New Folder',
    },
    selectAll       : {
        DE: 'Alle markieren',
        EN: 'Select All',
    },
    cut             : {
        DE: 'Ausschneiden',
        EN: 'Cut',
    },
    paste           : {
        DE: 'Einfügen',
        EN: 'Paste',
    },
    clearSelection  : {
        DE: 'Markierung entfernen',
        EN: 'Clear Selection',
    },
    open            : {
        DE: 'Öffnen',
        EN: 'Open',
    },
    preview         : {
        DE: 'Vorschau',
        EN: 'Preview',
    },
    deletedFiles    : {
        DE: 'Gelöschte Dateien',
        EN: 'Deleted Files',
    },
    trash           : {
        DE: 'Papierkorb',
        EN: 'Trash',
    },
    restore         : {
        DE: 'Wiederherstellen',
        EN: 'Restore',
    },

// **Organisation:**
    markAsFavorite: {
        DE: 'Als Favorit markieren',
        EN: 'Mark as Favorite',
    },
    favorites     : {
        DE: 'Favoriten',
        EN: 'Favorites',
    },
    bookmarks     : {
        DE: 'Lesezeichen',
        EN: 'Bookmarks',
    },
    markAsBookmark: {
        DE: 'Als Lesezeichen markieren',
        EN: 'Mark as Bookmark',
    },
    allFiles      : {
        DE: 'Alle Dateien',
        EN: 'All Files',
    },
    folders       : {
        DE: 'Ordner',
        EN: 'Folders',
    },
    hide          : {
        DE: 'Ausblenden',
        EN: 'Hide',
    },
    show          : {
        DE: 'Einblenden',
        EN: 'Show',
    },
    refresh       : {
        DE: 'Aktualisieren',
        EN: 'Refresh',
    },
    currentFiles  : {
        DE: 'Aktuelle Dateien',
        EN: 'Current Files',
    },
    expand        : {
        DE: 'Ausklappen',
        EN: 'Expand',
    },
    collapse      : {
        DE: 'Einklappen',
        EN: 'Collapse',
    },
    create        : {
        DE: 'Erstellen',
        EN: 'Create',
    },

// **Kontoverwaltung:**
    manageAccount      : {
        DE: 'Konto verwalten',
        EN: 'Manage Account',
    },
    deleteAccount      : {
        DE: 'Konto löschen',
        EN: 'Delete Account',
    },
    protectWithPassword: {
        DE: 'mit Passwort schützen',
        EN: 'Protect with Password',
    },

// **Design und Einstellungen:**
    design     : {
        DE: 'Design',
        EN: 'Design',
    },
    lightDesign: {
        DE: 'Helles Design',
        EN: 'Light Design',
    },
    darkDesign : {
        DE: 'Dunkles Design',
        EN: 'Dark Design',
    },
    settings   : {
        DE: 'Einstellungen',
        EN: 'Settings',
    },
    language   : {
        DE: 'Sprache',
        EN: 'Language',
    },

// **Speicherinformationen:**
    storageQuota        : {
        DE: 'Speicherkontingent',
        EN: 'Storage Quota',
    },
    of                  : {
        DE: 'von',
        EN: 'of',
    },
    used                : {
        DE: 'belegt',
        EN: 'used',
    },
    storageQuotaExceeded: {
        DE: 'Speicherkontingent aufgebraucht',
        EN: 'Storage Quota Exceeded',
    },

// **Benutzerinformationen:**
    emailUsername: {
        DE: 'E-Mail/Benutzername',
        EN: 'Email/Username',
    },
    firstName    : {
        DE: 'Vorname',
        EN: 'First Name',
    },
    lastName     : {
        DE: 'Nachname',
        EN: 'Last Name',
    },

// **Spaltenbeschriftung:**
    fileName  : {
        DE: 'Dateiname',
        EN: 'Filename',
    },
    fileSize  : {
        DE: 'Dateigröße',
        EN: 'File Size',
    },
    uploadDate: {
        DE: 'Uploaddatum',
        EN: 'Upload Date',
    },

// **Öffentliche Zugriffe:**
    share      : {
        DE: 'Teilen',
        EN: 'Share',
    },
    publish    : {
        DE: 'Freigeben',
        EN: 'Publish',
    },
    public     : {
        DE: 'Öffentlich',
        EN: 'Public',
    },
    sharedFiles: {
        DE: 'Geteilte Dateien',
        EN: 'Shared Files',
    },

// **Suchfunktion:**
    searchFilesAndFolders: {
        DE: 'Nach Dateien und Ordner suchen',
        EN: 'Search for Files and Folders',
    },
    search               : {
        DE: 'Suche',
        EN: 'Search',
    },

// **Multimedia- und Dateitypen:**
    images   : {
        DE: 'Bilder',
        EN: 'Images',
    },
    videos   : {
        DE: 'Videos',
        EN: 'Videos',
    },
    documents: {
        DE: 'Dokumente',
        EN: 'Documents',
    },
    audio    : {
        DE: 'Audio',
        EN: 'Audio',
    },

// **Information und Hilfe:**
    welcomeMessage       : {
        DE: 'Willkommen auf deiner Cloud-Plattform!',
        EN: 'Welcome to Your Cloud Platform!',
    },
    unsupportedFileType  : {
        DE: 'Dateityp wird nicht unterstützt.',
        EN: 'Unsupported File Type.',
    },
    noPreviewAvailable   : {
        DE: 'Für diesen Dateityp ist keine Vorschau verfügbar.',
        EN: 'No Preview Available for This File Type.',
    },
    help                 : {
        DE: 'Hilfe',
        EN: 'Help',
    },
    contactSupport       : {
        DE: 'Kontaktiere den Support',
        EN: 'Contact Support',
    },
    reportProblem        : {
        DE: 'Problem melden',
        EN: 'Report a Problem',
    },
    tour                 : {
        DE: 'Tour',
        EN: 'Tour',
    },
    privacy              : {
        DE: 'Datenschutz',
        EN: 'Privacy',
    },
    privacyAndLegal      : {
        DE: 'Datenschutz und Rechtliches',
        EN: 'Privacy and Legal',
    },
    termsOfUse           : {
        DE: 'Nutzungsbedingungen',
        EN: 'Terms of Use',
    },
    cookieAndCCPASettings: {
        DE: 'Cookie- und CCPA-Einstellungen',
        EN: 'Cookie and CCPA Settings',
    },

// **Tooltips & Infotexte:**

    cloudUploadFailed: {
        DE: 'Cloud Upload gescheitert',
        EN: 'Cloud Upload Failed',
    },

    uploadCancelled: {
        DE: 'Upload wurde abgebrochen, bitte versuche es später erneut!',
        EN: 'Upload was cancelled, please try again later!',
    },

    signInRequired: {
        DE: 'Melden Sie sich an, um Zugriff auf Ihre Dateien zu erhalten.',
        EN: 'Sign in to access your files.',
    },

    noAccountYet: {
        DE: 'Sie haben noch kein Konto? Registrieren',
        EN: 'Don\'t have an account yet? Register',
    },

    stayLoggedIn: {
        DE: 'Eingeloggt bleiben',
        EN: 'Stay Logged In',
    },

    saveShareManageContent: {
        DE: 'Speichern, teilen und verwalten Sie alle Ihre Inhalte.',
        EN: 'Save, share, and manage all your content.',
    },

    shareFilesWithCloudFeatures: {
        DE: 'Dateien einfach teilen mit unseren Cloud-Funktionen',
        EN: 'Easily share files with our cloud features',
    },

    effortlessFileSharing: {
        DE: 'Teilen Sie mühelos Dateien mit Kollegen und Freunden. Kopieren Sie einfach den Link und senden Sie ihn weiter.',
        EN: 'Effortlessly share files with colleagues and friends. Simply copy the link and share.',
    },

    oopsPageNotAvailable: {
        DE: 'Hoppla! Diese Seite ist nicht verfügbar.',
        EN: 'Oops! This page is not available.',
    },

    invalidLinkErrorMessage: {
        DE: 'Entschuldigung, es sieht so aus, als ob der angeforderte Link nicht mehr gültig ist. Bitte überprüfen Sie die URL oder melden Sie sich an, um fortzufahren.',
        EN: 'Sorry, it looks like the requested link is no longer valid. Please check the URL or sign in to continue.',
    },

    cloudDataBackup: {
        DE: 'Sichern Sie Ihre Daten in der Cloud',
        EN: 'Backup your data in the cloud',
    },

    fileSharingMadeEasy: {
        DE: 'File Sharing leicht gemacht',
        EN: 'File sharing made easy',
    },

    easyFileSharingDescription: {
        DE: 'Einfaches Teilen von Dateien mit unseren benutzerfreundlichen Cloud-Funktionen. Melden Sie sich jetzt an und erleben Sie nahtloses File Sharing.',
        EN: 'Easily share files with our user-friendly cloud features. Sign up now and experience seamless file sharing.',
    },

    welcomeToPlatform: {
        DE: 'Willkommen bei [Platformname]! Um die Vorteile unserer Cloud-Services zu nutzen, registrieren Sie sich jetzt. Geben Sie Ihre E-Mail Adresse und ein sicheres Passwort ein, um loszulegen.',
        EN: 'Welcome to [Platformname]! To enjoy the benefits of our cloud services, register now. Enter your email address and a secure password to get started.',
    },

    currentStorageQuota: {
        DE: 'Dein aktuelles Speicherkontingent beträgt [X GB]. Verwalte deine Dateien, um Platz zu sparen, oder erweitere dein Kontingent für zusätzlichen Speicherplatz.',
        EN: 'Your current storage quota is [X GB]. Manage your files to save space, or expand your quota for additional storage.',
    },

    pageTemporarilyUnavailable: {
        DE: 'Entschuldigung, die angeforderte Seite ist vorübergehend nicht verfügbar. Wir arbeiten daran, das Problem zu beheben. Bitte versuche es später erneut.',
        EN: 'Sorry, the requested page is temporarily unavailable. We are working to fix the issue. Please try again later.',
    },

    twoFactorAuthentication: {
        DE: 'Zwei Faktor Authentifizierung',
        EN: 'Two-Factor Authentication',
    },

    addExtraSecurityLayer: {
        DE: 'Füge eine zusätzliche Sicherheitsebene hinzu! Aktiviere die Zwei Faktor Authentifizierung in den Kontoeinstellungen, um dein Konto besser zu schützen.',
        EN: 'Add an extra security layer! Enable two-factor authentication in your account settings to better protect your account.',
    },

    fileSharingAndPermissions: {
        DE: 'Dateifreigabe und Berechtigungen',
        EN: 'File Sharing and Permissions',
    },

    manageSharedLinks: {
        DE: 'Verwalte deine freigegebenen Links. Ändere die Berechtigungen, deaktiviere oder lösche Links, um die Sicherheit deiner geteilten Dateien zu gewährleisten.',
        EN: 'Manage your shared links. Change permissions, disable, or delete links to ensure the security of your shared files.',
    },

    maxFileSizeWarning: {
        DE: 'Achte auf die maximale Dateigröße für den Upload. Überprüfe unsere Richtlinien, um sicherzustellen, dass deine Dateien problemlos hochgeladen werden können.',
        EN: 'Watch out for the maximum file size for uploads. Check our guidelines to ensure your files can be uploaded smoothly.',
    },

    maxFileSizeExceededError: {
        DE: 'Oops! Es scheint, als ob du die maximale Dateigröße erreicht hast. Bitte überprüfe die Dateigröße und versuche es mit einer kleineren Datei erneut oder prüfe, ob eine höhere Speicherkontingent Option verfügbar ist, um größere Dateien hochzuladen.',
        EN: 'Oops! It seems you have reached the maximum file size. Please check the file size and try with a smaller file or check if a higher storage quota option is available for larger uploads.',
    },

    learnMoreAboutPrivacy: {
        DE: 'Erfahre mehr über unsere Datenschutzrichtlinien. Wir setzen alles daran, deine persönlichen Daten zu schützen und die höchsten Datenschutzstandards einzuhalten.',
        EN: 'Learn more about our privacy policies. We are committed to protecting your personal data and adhering to the highest privacy standards.',
    },

    securityChecks: {
        DE: 'Sicherheitsüberprüfungen',
        EN: 'Security Checks',
    },

    regularSecurityAudits: {
        DE: 'Unsere regelmäßigen Sicherheitsüberprüfungen gewährleisten, dass deine Daten geschützt sind. Erfahre mehr darüber in unseren Sicherheitsrichtlinien.',
        EN: 'Our regular security audits ensure that your data is protected. Learn more about it in our security policies.',
    },

    userFriendlyInterface: {
        DE: 'Benutzerfreundliche Oberfläche',
        EN: 'User-Friendly Interface',
    },

    discoverUserFriendlyInterface: {
        DE: 'Entdecke unsere benutzerfreundliche Oberfläche! Navigiere mühelos durch die Cloud, finde Dateien schnell und verwalte deine Inhalte mit Leichtigkeit.',
        EN: 'Discover our user-friendly interface! Navigate through the cloud effortlessly, find files quickly, and manage your content with ease.',
    },
    more                         : {
        DE: 'Mehr',
        EN: 'More',
    },
    createFolderToStart          : {
        DE: 'Erstelle zu Beginn einen neuen Ordner',
        EN: 'Create a new folder to start',
    },
    stopSharing                  : {
        DE: 'Stop sharing',
        EN: 'Teilen beenden',
    },
    dropFileHere                 : {
        DE: 'Datei hier ablegen',
        EN: 'Drop file here',
    },
    getStarted                   : {
        DE: 'Loslegen',
        EN: 'Get started',
    }
}

let i18n = {};
// eslint-disable-next-line array-callback-return
Object.keys(translations).map(o => {
    i18n[o] = translations[o]['EN']
});

const TranslationReducer = (state = {}, user, action) => {
    let newState = {};
    switch (action.type) {
        case 'STORE_USER': {
            console.log(action);
            if (action.payload.Language && user.Language !== action.payload.Language) {
                // eslint-disable-next-line array-callback-return
                Object.keys(translations).map(o => {
                    newState[o] = translations[o][action.payload.Language]
                });
                i18n = newState;
                return newState;
            }
            return state;
        }
        case 'SET_LANGUAGE': {
            if (action.payload && user.Language !== action.payload) {
                // eslint-disable-next-line array-callback-return
                Object.keys(translations).map(o => {
                    newState[o] = translations[o][action.payload]
                });
                i18n = newState;
                return newState;
            }
            return state;
        }
        default:
            return state;
    }
};

export default TranslationReducer;
export {i18n};